import React, { useState, useEffect } from "react"

import "./styles.scss"
import Banner from "../../components/Banner"
import Up from "../../assets/drawable-xxhdpi/fi_chevron_up.png"
import Down from "../../assets/drawable-xxhdpi/fi_chevron_down.png"

const Item = ({ item }) => {
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
        setIsOpen(false)
    }, [item])
    return (
        <div className="notice" onClick={() => setIsOpen(!isOpen)}>
            <div className="top">
                <span className="title">{item.title}</span>
                <span className="date">{item.date}</span>
                <img src={isOpen ? Up : Down} />
            </div>
            {isOpen && (
                <div className="content">
                    <div>{item.content}</div>
                </div>
            )}
        </div>
    )
}

export default function Notice() {
    const NOTICE_LIST = [
        {
            id: 11,
            title: "신주발행 공고",
            date: "2025-01-03",
            content: (
                <>
                    <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "26px", marginBottom: "50px" }}>신주발행 공고</p>
                    <div>
                        당사는 2025년 01월 03일 이사회에서 제3자배정 방식으로 신주를 발행하기로 결의하였으므로 상법 제418조 제4항에 따라 다음과 같이
                        신주발행사항을 공고합니다.
                    </div>
                    <p>&nbsp;</p>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        다&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;음
                    </p>
                    <p>1. 신주의 총수와 수 : 보통주식 21,900주</p>
                    <p>2. 신주의 발행가격과 납입기일</p>
                    <p>&nbsp;&nbsp;&nbsp;1) 발행가액 : 1주당 9,132.42원</p>
                    <p>&nbsp;&nbsp;&nbsp;2) 납입기일 : 2025년 01월 21일</p>
                    <p>3. 신주의 인수방법 : 정관 13조 제2항 제8호에 따른 제3자배정</p>
                    <p>&nbsp;</p>
                    <p style={{ textAlign: "center" }}>2025년 01월 03일</p>
                    <p>&nbsp;</p>
                    <div className="notice-bottom">
                        <div>
                            <span style={{ position: "absolute", left: "-30%" }}>공&nbsp;&nbsp;고&nbsp;&nbsp;인</span>
                            <p>주식회사 셀러비코리아</p>
                        </div>
                        <p>서울특별시 강남구 테헤란로20길 10,</p>
                        <p>10층 (역삼동)</p>
                        <p>대표이사 권 혁 문</p>
                    </div>
                </>
            ),
        },
        {
            id: 10,
            title: "신주발행 공고",
            date: "2024-11-01",
            content: (
                <>
                    <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "26px", marginBottom: "50px" }}>신주발행 공고</p>
                    <div>
                        당사는 2024년 10월 31일 이사회에서 제3자배정 방식으로 신주를 발행하기로 결의하였으므로 상법 제418조 제4항에 따라 다음과 같이
                        신주발행사항을 공고합니다.
                    </div>
                    <p>&nbsp;</p>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        다&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;음
                    </p>
                    <p>1. 신주의 총수와 수 : 보통주식 17,705주</p>
                    <p>2. 신주의 발행가격과 납입기일</p>
                    <p>&nbsp;&nbsp;&nbsp;1) 발행가액 : 1주당 14,121원</p>
                    <p>&nbsp;&nbsp;&nbsp;2) 납입기일 : 2024년 11월 18일</p>
                    <p>3. 신주의 인수방법 : 정관 13조 제2항 제8호에 따른 제3자배정</p>
                    <p>&nbsp;</p>
                    <p style={{ textAlign: "center" }}>2024년 11월 01일</p>
                    <p>&nbsp;</p>
                    <div className="notice-bottom">
                        <div>
                            <span style={{ position: "absolute", left: "-30%" }}>공&nbsp;&nbsp;고&nbsp;&nbsp;인</span>
                            <p>주식회사 셀러비코리아</p>
                        </div>
                        <p>서울특별시 강남구 테헤란로20길 10,</p>
                        <p>10층 (역삼동)</p>
                        <p>대표이사 권 혁 문</p>
                    </div>
                </>
            ),
        },
        {
            id: 9,
            title: "신주발행 공고",
            date: "2024-10-25",
            content: (
                <>
                    <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "26px", marginBottom: "50px" }}>신주발행 공고</p>
                    <div>
                        당사는 2024년 10월 24일 이사회에서 제3자배정 방식으로 신주를 발행하기로 결의하였으므로 상법 제418조 제4항에 따라 다음과 같이
                        신주발행사항을 공고합니다.
                    </div>
                    <p>&nbsp;</p>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        다&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;음
                    </p>
                    <p>1.신주의 종류와 수: 보통주식 24,786주</p>
                    <p>2.신주의 발행가액과 납입기일</p>
                    <p>&nbsp;&nbsp;&nbsp;1) 발행가액: 1주당 14,121원</p>
                    <p>&nbsp;&nbsp;&nbsp;2) 납입기일: 2024년 11월 10일</p>
                    <p>3. 신주의 인수방법: 정관 제13조 제2항 제8호에 따른 제3자배정</p>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p style={{ textAlign: "center" }}>2024년 10월 25일</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div className="notice-bottom">
                        <div>
                            <span style={{ position: "absolute", left: "-30%" }}>공&nbsp;&nbsp;고&nbsp;&nbsp;인</span>
                            <p>주식회사 셀러비코리아</p>
                        </div>
                        <p>서울특별시 강남구 테헤란로20길 10,</p>
                        <p> 10층 (역삼동)</p>
                        <p>대표이사 권 혁 문</p>
                    </div>
                </>
            ),
        },
        {
            id: 7,
            title: "임시주주총회 소집을 위한 기준일 및 주주명부 폐쇄기간(주식명의개서정지) 설정 공고",
            date: "2024-08-12",
            content: (
                <>
                    <div>
                        {" "}
                        셀러비코리아는 상법 제 354조 및 당사 정관 제14조에 의거하여
                        <br />
                        당사의 임시주주총회에서 의결권을 행사할 수 있는 주주를 정하기 위하여 아래와 같이 주주명부 확정기준일을 정하고 주식의 명의개서,
                        질권의 등록 및 변경과 말소, 신탁재산의 표시 및 말소 등 주주명부 기재사항 변경을 정지함을 공고 합니다.
                    </div>
                    <p>&nbsp;</p>
                    <p>1.주주명부확정 기준일 : 2024년 08월 27일</p>
                    <p>2.주주명부 폐쇄기간 : 2024년 08월28일~ 2024년 09월 01일</p>
                    <p>3.임시주주총회 일시 : 2024년 09월 23일(월) 오전 10시</p>
                    <p>4.임시주주총회 장소 : 서울특별시 강남구 테헤란로20길 10 쓰리엠타워 10층 본점 대회의실</p>

                    <p>&nbsp;</p>
                    <p style={{ textAlign: "center" }}>2024년 08월 12일</p>
                    <p>&nbsp;</p>
                    <div style={{ marginLeft: "20%" }}>
                        <p>주식회사 셀러비코리아 (직인생략)</p>
                        <p>서울특별시 강남구 테헤란로20길 10 쓰리엠타워 10층</p>
                        <p>대표이사 권 혁 문</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>명의개서대리인 (직인생략)</p>
                        <p>주식회사 하나은행</p>
                        <p>은행장 이 승 열</p>
                    </div>
                </>
            ),
        },
        {
            id: 6,
            title: "주식명의개서 정지 공고",
            date: "2023-09-18",
            content: (
                <>
                    <div>
                        {" "}
                        &nbsp;&nbsp;상법 제354조에 의거 액면분할을 위하여 주식의 명의개서, 질권의 등록, 말소와 신탁재산의 표시 및 말소 등 주주명부의
                        기재사항 변경을 다음과 같이 정지하오니 양지하시기 바랍니다.
                    </div>
                    <p>&nbsp;</p>
                    <p>1. 주주확정일: 2023년 10월 18일</p>
                    <p>2. 명의개서정지기간: 2023년 10월 19일 ~ 2023년 10월 26일</p>

                    <p>&nbsp;</p>
                    <p style={{ textAlign: "center" }}>2023년 09월 18일</p>
                    <p>&nbsp;</p>
                    <div style={{ marginLeft: "20%" }}>
                        <p>주식회사 셀러비코리아 (직인생략)</p>
                        <p>서울특별시 중구 세종대로 14, 비동 8층 (남대문로 5가)</p>
                        <p>대표이사 박 성 훈</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>명의개서대리인 (직인생략)</p>
                        <p>주식회사 하나은행</p>
                        <p>은행장 박 성 호</p>
                    </div>
                </>
            ),
        },
        {
            id: 5,
            title: "주식의 액면분할로 인한 구주권 제출 공고",
            date: "2023-09-18",
            content: (
                <>
                    <div>
                        {" "}
                        &nbsp;&nbsp;당사는 2023년 09월 15일 임시주주총회 결의에 의거 1주의 액면금액 5,000원의 주식 1주를 1주의 액면금액 100원의 주식
                        50주로 분할하여 신주권으로 교체 발행하고자 하오니 다음과 같이 구주권을 제출하여 주시기 바랍니다.
                    </div>
                    <p>&nbsp;</p>
                    <p>1. 구주권의 제출기한: 2023년 09월 18일 ~ 2023년 10월 18일</p>
                    <p>2. 구주권의 제출 및 신주권 교부처: KEB하나은행 증권대행부</p>
                    <p>3. 신주권 교부예정일: 2023년 10월 27일</p>
                    <p>4. 기타사항</p>
                    <div style={{ paddingLeft: "20px" }}>
                        <p>(1) 증권사 예탁 주주는 구주권 제출 없이 증권사 예탁계좌에서 자동으로 대체될 예정입니다. </p>
                        <p> (2) 주주명부 등재 주주로서 주식을 직접 소지하고 있는 주주는 구주권을 제출해야 합니다.</p>
                        <p>(3) 2023년 10월 27일부터 구주권의 유통은 금지됩니다.</p>
                        <p> (4) 위 일정은 관계기관과의 협의나 관련 법규에 따라 일부 변경될 수 있습니다.</p>
                    </div>
                    <p>&nbsp;</p>
                    <p style={{ textAlign: "center" }}>2023년 09월 18일</p>
                    <p>&nbsp;</p>
                    <div style={{ marginLeft: "20%" }}>
                        <p>주식회사 셀러비코리아 (직인생략)</p>
                        <p>서울특별시 중구 세종대로 14, 비동 8층 (남대문로 5가)</p>
                        <p>대표이사 박 성 훈</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>명의개서대리인 (직인생략)</p>
                        <p>주식회사 하나은행</p>
                        <p>은행장 박 성 호</p>
                    </div>
                </>
            ),
        },
        // {
        //     id: 4,
        //     title: '임시 주주총회 의결권 행사 기준일 설정 공고',
        //     date: '2023-08-03',
        //     content: <>
        //         <div> &nbsp;&nbsp;당사는 상법 제354조 및 정관 제19조에 따라 2023년 09월 15일 개최 예정인 임시 주주총회에서 의결권을 행사할 주주를 정하기 위한 기준일을 다음과 같이 설정하였으므로 이에 공고합니다.</div>
        //         <p>&nbsp;</p>
        //         <p>1. 주주총회 의결권 행사 기준일: 2023년 08월 18일</p>
        //         <p>&nbsp;</p>
        //         <p style={{ textAlign: 'center' }}>2023년 08월 02일</p>
        //         <p>&nbsp;</p>
        //         <div style={{ marginLeft: '20%' }}>
        //             <p>주식회사 셀러비코리아 (직인생략)</p>
        //             <p>서울특별시 중구 세종대로 14, 비동 8층 (남대문로 5가)</p>
        //             <p>대표이사 박  성  훈</p>
        //             <p>&nbsp;</p>
        //             <p>&nbsp;</p>
        //             <p>명의개서대리인 (직인생략)</p>
        //             <p>주식회사 하나은행</p>
        //             <p>은행장 박  성  호</p>
        //         </div>
        //     </>
        // }
        // {
        //     id: 3,
        //     title: '임시 주주총회 의결권 행사 기준일 설정 공고',
        //     date: '2023-06-19',
        //     content: <>
        //         <div> &nbsp;&nbsp;당사는 상법 제354조 및 정관 제19조에 따라 2023년 08월 03일 개최 예정인 임시 주주총회에서 의결권을 행사할 주주를 정하기 위한 기준일을 다음과 같이 설정하였으므로 이에 공고합니다.</div>
        //         <p>&nbsp;</p>
        //         <p>1. 주주총회 의결권 행사 기준일: 2023년 07월 03일</p>
        //         <p>&nbsp;</p>
        //         <p style={{ textAlign: 'center' }}>2023년 06월 19일</p>
        //         <p>&nbsp;</p>
        //         <div style={{ marginLeft: '20%' }}>
        //             <p>주식회사 셀러비코리아 (직인생략)</p>
        //             <p>서울특별시 중구 세종대로 14, 비동 8층 (남대문로 5가)</p>
        //             <p>대표이사 박  성  훈</p>
        //             <p>&nbsp;</p>
        //             <p>&nbsp;</p>
        //             <p>명의개서대리인 (직인생략)</p>
        //             <p>주식회사 하나은행</p>
        //             <p>은행장 박  성  호</p>
        //         </div>
        //     </>
        // }
        // {
        //     id: 1,
        //     title: '주식의 액면분할로 인한 구주권 제출공고',
        //     date: '2023-01-27',
        //     content: <>
        //         <p>주주 여러분께</p>
        //         <p>&nbsp;</p>
        //         <p>주식의 액면분할로 인한 구주권 제출공고</p>
        //         <p>&nbsp;</p>
        //         <div> &nbsp;&nbsp;당사는 2023년 01월 27일 임시주주총회 결의에 의거 1주의 액면금액 5,000원의 주식 1주를 1주의 액면금액 100원의 주식 50주로 분할하여 신주권으로 교체발행하고자 하오니 다음과 같이 구주권을 제출하여 주시기 바랍니다.</div>
        //         <p>&nbsp;</p>
        //         <p>1. 구주권의 제출기한: 2023년 01월 27일 ~ 2023년 02월 27일</p>
        //         <p>2. 구주권의 제출 및 신주권 교부처: KEB하나은행 증권대행부</p>
        //         <p>3. 신주권 교부예정일: 2023년 03월 13일</p>
        //         <p>4. 기타사항</p>
        //         <div style={{ paddingLeft: '30px', borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
        //             <p>⑴ 증권사 예탁 주주는 구주권 제출 없이 증권사 예탁계좌에서 자동으로 대체될 예정입니다.</p>
        //             <p>⑵ 주주명부 등재 주주로서 주식을 직접 소지하고 있는 주주는 구주권을 제출해야 합니다.</p>
        //             <p>⑶ 2023년 03월 13일부터 구주권의 유통은 금지됩니다.</p>
        //             <p>⑷ 위 일정은 관계기관과의 협의나 관련 법규에 따라 일부 변경될 수 있습니다.</p>
        //         </div>
        //         <p>&nbsp;</p>
        //         <p>주식명의개서 정지 공고</p>
        //         <p>&nbsp;</p>
        //         <div> &nbsp;&nbsp;상법 제354조에 의거 액면분할을 위하여 주식의 명의개서, 질권의 등록, 말소와 신탁재산의 표시 및 말소 등 주주명부의 기재사항 변경을 다음과 같이 정지하오니 양지하시기 바랍니다.</div>
        //         <p>&nbsp;</p>
        //         <div style={{ paddingLeft: '30px' }}>
        //             <p>1. 주주확정일: 2023년 02월 27일</p>
        //             <p>2. 명의개서정지기간: 2023년 02월 28일 ~ 2023년 03월 12일 </p>
        //         </div>
        //         <p>&nbsp;</p>
        //         <p style={{ textAlign: 'center' }}>2023년 01월 27일</p>
        //         <p>&nbsp;</p>
        //         <div style={{ marginLeft: '20%' }}>
        //             <p>주식회사 셀러비코리아 (직인생략)</p>
        //             <p>서울특별시 중구 세종대로 14, 비동 8층 (남대문로 5가)</p>
        //             <p>대표이사 박  성  훈</p>
        //             <p>&nbsp;</p>
        //             <p>&nbsp;</p>
        //             <p>명의개서대리인 (직인생략)</p>
        //             <p>주식회사 하나은행</p>
        //             <p>은행장 이  승  열</p>
        //         </div>

        //     </>
        // },
        // {
        //     id: 2,
        //     title: '임시 주주총회 의결권 행사 기준일 설정 공고',
        //     date: '2022-12-16',
        //     content: <>
        //         <div> &nbsp;&nbsp;당사는 상법 제354조 및 정관 제17조에 따라 2023년 01월 27일 개최 예정인 임시 주주총회에서 의결권을 행사할 주주를 정하기 위한 기준일을 다음과 같이 설정하였으므로 이에 공고합니다.</div>
        //         <p>&nbsp;</p>
        //         <p>1. 주주총회 의결권 행사 기준일: 2023년 01월 02일</p>
        //         <p>&nbsp;</p>
        //         <p style={{ textAlign: 'center' }}>2022년 12월 16일</p>
        //         <p>&nbsp;</p>
        //         <div style={{ marginLeft: '20%' }}>
        //             <p>주식회사 셀러비코리아 (직인생략)</p>
        //             <p>서울특별시 중구 세종대로 14, 비동 8층 (남대문로 5가)</p>
        //             <p>대표이사 박  성  훈</p>
        //             <p>&nbsp;</p>
        //             <p>&nbsp;</p>
        //             <p>명의개서대리인 (직인생략)</p>
        //             <p>주식회사 하나은행</p>
        //             <p>은행장 박  성  호</p>
        //         </div>
        //     </>
        // },
        // {
        //     id: 1,
        //     title: '임시 주주총회 의결권 행사 기준일 설정 공고',
        //     date: '2022-09-29',
        //     content: <>
        //         <div> &nbsp;&nbsp;당사는 상법 제354조 및 정관 제17조에 따라 2022년 11월 16일 개최 예정인 임시 주주총회에서 의결권을 행사할 주주를 정하기 위한 기준일을 다음과 같이 설정하였으므로 이에 공고합니다.</div>
        //         <p>&nbsp;</p>
        //         <p>1. 주주총회 의결권 행사 기준일: 2022년 10월 17일</p>
        //         <p>&nbsp;</p>
        //         <p>&nbsp;</p>
        //         <p style={{ textAlign: 'center' }}>2022년 09월 29일</p>
        //         <p>&nbsp;</p>
        //         <p>&nbsp;</p>
        //         <div style={{ marginLeft: '20%' }}>
        //             <p>주식회사 셀러비코리아 (직인생략)</p>
        //             <p>서울특별시 중구 세종대로 14, 비동 8층 (남대문로 5가)</p>
        //             <p>대표이사 박  성  훈</p>
        //             <p>&nbsp;</p>
        //             <p>&nbsp;</p>
        //             <p>명의개서대리인 (직인생략)</p>
        //             <p>주식회사 하나은행</p>
        //             <p>은행장 박  성  호</p>
        //         </div>
        //     </>
        // },
        // {
        //     id: 2,
        //     title: '주식의 액면분할로 인한 구주권 제출공고',
        //     date: '2022-07-19',
        //     content: <>
        //         <p> 당사는 2022년 07월 18일 임시주주총회 결의에 의거 1주의 액면금액 5,000원의 주식 1주를 <br/>1주의 액면금액 100원의 주식 50주로 분할하여 신주권으로 교체발행하고자 하오니 다음과 같이 구주권을 제출하여 주시기 바랍니다.</p>
        //         <p>&nbsp;</p>
        //         <p>1. 구주권의 제출기한: 2022년 07월 19일 ~ 2022년 08월 19일</p>
        //         <p>2. 구주권의 제출 및 신주권 교부처: KEB하나은행 증권대행부</p>
        //         <p>3. 신주권 교부예정일: 2022년 08월 29일</p>
        //         <p>4. 기타사항</p>
        //         <p>&nbsp;&nbsp;&nbsp;&nbsp;⑴ 증권사 예탁 주주는 구주권 제출 없이 증권사 예탁계좌에서 자동으로 대체될 예정입니다. </p>
        //         <p>&nbsp;&nbsp;&nbsp;&nbsp;⑵ 주주명부 등재 주주로서 주식을 직접 소지하고 있는 주주는 구주권을 제출해야 합니다.</p>
        //         <p>&nbsp;&nbsp;&nbsp;&nbsp;⑶ 2022년 08월 29일부터 구주권의 유통은 금지됩니다.</p>
        //         <p>&nbsp;&nbsp;&nbsp;&nbsp;⑷ 위 일정은 관계기관과의 협의나 관련 법규에 따라 일부 변경될 수 있습니다.</p>
        //         <p>-------------------------------------------------------------------------------------</p>
        //         <p>주식명의개서 정지 공고</p>
        //         <p>&nbsp;</p>
        //         <p>상법 제354조에 의거 액면분할을 위하여 주식의 명의개서, 질권의 등록, 말소와 신탁재산의 표시 및 말소 등 주주명부의 기재사항 변경을 <br />다음과 같이 정지하오니 양지하시기 바랍니다.</p>
        //         <p>&nbsp;</p>
        //         <p>1. 주주확정일: 2022년 08월 19일</p>
        //         <p>2. 명의개서정지기간: 2022년 08월 20일 ~ 2022년 08월 28일</p>
        //         <p>&nbsp;</p>
        //         <p>2022년 07월 18일</p>
        //         <p>&nbsp;</p>
        //         <p>서울특별시 중구 세종대로 14, 비동 8층 (남대문로 5가)</p>
        //         <p>주식회사 셀러비코리아</p>
        //         <p>대표이사 박  성  훈</p>
        //         <p>&nbsp;</p>
        //         <p>&nbsp;</p>
        //         <p>명의개서대리인</p>
        //         <p>주식회사 하나은행</p>
        //         <p>은행장 박  성  호</p>
        //     </>
        // },
        // {
        //     id: 1,
        //     title: '임시 주주총회 의결권 행사 기준일 설정 공고',
        //     date: '2022-12-16',
        //     content: <>
        //         <p>당사는 상법 제354조 및 정관 제17조에 따라</p>
        //         <p>2023년 01월 27일 개최 예정인 임시 주주총회에서 의결권을 행사할 주주를 정하기 위한 기준일을</p>
        //         <p>다음과 같이 설정하였으므로 이에 공고합니다.</p>
        //         <p>&nbsp;</p>
        //         <p>1. 주주총회 의결권 행사 기준일: 2023년 01월 02일</p>
        //         <p>&nbsp;</p>
        //         <p>&nbsp;</p>
        //         <p>2022년 12월 16일</p>
        //         <p>&nbsp;</p>
        //         <p>&nbsp;</p>
        //         <p>주식회사 셀러비코리아 (직인생략)</p>
        //         <p>서울특별시 중구 세종대로 14, 비동 8층 (남대문로 5가)</p>
        //         <p>대표이사 박  성  훈</p>
        //         <p>&nbsp;</p>
        //         <p>&nbsp;</p>
        //         <p>명의개서대리인 (직인생략)</p>
        //         <p>주식회사 하나은행</p>
        //         <p>은행장 박  성  호</p>
        //     </>
        // }
    ]

    return (
        <div className="notice-wrap">
            <Banner title="공지사항" />
            <div className="in-wrap">
                <div className="notice-list">
                    {NOTICE_LIST.map((item, index) => (
                        <Item item={item} key={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}
